import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { paths } from "../types/Defaults";
import { useState } from "react";

export function PortfolioHomeLongShort() {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <div className="home-header-content--part2b">
        <Container className="pt-4 pb-4">
          <Row className="justify-content-center">
            <Col md={3} className="home-header-content-cell pt-5 mb-5">
              <span className="img-home flex-shrink-0">
                <img
                  className="img-fluid"
                  src="/images/home.svg"
                  alt="Trendrating"
                />
              </span>
            </Col>

            <Col md={8} className="home-header-content-cell pt-5">
              <div className="">
                <p className="fs-3 pb-2">
                  <p className="fs-3 pb-2">
                    A new disruptive solution for portfolio managers is here. In
                    the changed stock market cycle strategies that work in a
                    bull trend can disappoint. Active strategies combining long
                    and short exposure on stocks are better tuned to the new
                    course exploiting the trends dispersion and providing
                    attractive returns and low risks. Exploit the performance
                    dispersion.
                  </p>
                </p>

                <table className=" heroTable">
                  <tr className=" heroTable__headers">
                    <th>&nbsp;</th>
                    <th>US</th>
                    <th>Developed Europe</th>
                    {/* <th>Asia Pacific</th> */}
                  </tr>
                  <tr>
                    <td>Benchmark</td>
                    <td>
                      SPX <span className="positiveValue">+25%</span>
                    </td>
                    <td>
                      SXXP <span className="positiveValue">+25%</span>
                    </td>
                    {/* <td>
                      MSCI Pacific AC <span className="negativeValue">-4%</span>
                    </td> */}
                  </tr>
                  <tr>
                    <td>Top 25% Performers</td>
                    <td>
                      <span className="positiveValue">+56%</span>
                    </td>
                    <td>
                      <span className="positiveValue">+45%</span>
                    </td>
                    {/* <td>
                      <span className="positiveValue">+31%</span>
                    </td> */}
                  </tr>
                  {/* <tr>
                        <td>Mid 50% Performers</td>
                        <td>
                          <span className="positiveValue">+2.28%</span>
                        </td>
                        <td>
                          <span className="negativeValue">-7.82%</span>
                        </td>
                        <td>
                          <span className="negativeValue">-2.27%</span>
                        </td>
                      </tr> */}
                  <tr>
                    <td>Bottom 25% Performers</td>
                    <td>
                      <span className="negativeValue">-20%</span>
                    </td>
                    <td>
                      <span className="positiveValue">+6%</span>
                    </td>
                    {/* <td>
                      <span className="negativeValue">-20%</span>
                    </td> */}
                  </tr>
                </table>
                <p className="mb-5 pt-3">2024 Performance Dispersion Facts.</p>

                <p className="">
                  <strong>
                    <Link
                      to={paths.product}
                      className="btn btn-lg btn-primary fs-2 button-zoom btn-rounded"
                    >
                      Discover our smart model portfolios
                    </Link>
                    {/* Keep this comment 
                                                {isLogged ? (
                                                    <Link
                                                        to={paths.product}
                                                        className="btn btn-lg btn-primary fs-2 button-zoom btn-rounded"
                                                    >
                                                        Discover our smart model
                                                        portfolios
                                                    </Link>
                                                ) : (
                                                    <button
                                                        onClick={() =>
                                                            eventBus.dispatch(
                                                                "show-user-login-box",
                                                                true
                                                            )
                                                        }
                                                        className="btn btn-lg btn-primary fs-2 button-zoom btn-rounded"
                                                        type="button"
                                                    >
                                                        Discover our smart model
                                                        portfolios
                                                    </button>
                                                )} */}
                  </strong>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="home-header-content home-header-content--part6">
        <div className="home-header-content-mask"></div>
        <Container className="">
          <Row className="justify-content-center">
            <Col md={12} className="home-header-content-cell pt-5 ">
              <div className="text-center fs-2 mb-5">
                <h5 className="fs-2">
                  Profit from the performance dispersion with a new generation
                  of active model portfolios.
                </h5>
                <h5 className="fs-2">
                  <strong>
                    See our catalogue, track the performance, compare and
                    decide.
                  </strong>
                </h5>
              </div>
            </Col>

            <Col
              xs={12}
              className="home-header-content-cell d-flex mb-5 justify-content-center"
            >
              <div
                style={{ zIndex: 10 }}
                onClick={() => {
                  setShowMore(!showMore);
                }}
                className="knowMoreBtn button-zoom fs-2 px-5 py-2"
              >
                <strong> {showMore ? "Less" : "More"}</strong>{" "}
                {showMore ? (
                  <i className="bi bi-chevron-up"></i>
                ) : (
                  <i className="bi bi-chevron-down"></i>
                )}
              </div>
            </Col>

            <>
              {showMore && (
                <Col className="mb-5" style={{ zIndex: 100 }} xs={12} md={10}>
                  {/* <Accordion defaultActiveKey="0" alwaysOpen={false}>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header
                            style={{
                              fontFamily: "Roboto Condensed, sans-serif",
                            }}
                          >
                            <span className="fs-3">
                              <strong>The challenge</strong>
                            </span>
                          </Accordion.Header>
                          <Accordion.Body
                            style={{
                              color: "black",
                              textShadow: "none",
                            }}
                            className="fs-5"
                          >
                            The new market cycle is changing the game for
                            product issuers. The end of the 12 years bull market
                            will expose the inadequacy of many investable
                            products that prospered in the good days. As
                            investors realize that a passive product can be a
                            source of repetitive losses, they will shift assets
                            elsewhere. And the opportunity is filling the
                            inevitable future demand of intelligent products
                            that can deliver gains also in a low to negative
                            returns cycle that can last years.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header
                            style={{
                              fontFamily: "Roboto Condensed, sans-serif",
                            }}
                          >
                            <span className="fs-3">
                              <strong>The solution</strong>{" "}
                            </span>
                          </Accordion.Header>
                          <Accordion.Body
                            style={{ color: "black", textShadow: "none" }}
                            className="fs-5"
                          >
                            The way forward is advanced active management,
                            profiting from the recurrent performance dispersion
                            inherent in any investment universe across different
                            market cycles. Farsighted operators launching
                            innovative products will capture a significant share
                            of the assets reallocation at the expense of those
                            that will persist with a conventional offer range,
                            detached from the new course. Trendrating solution
                            can support the launch of long and short products
                            combining fundamentals and trend capture models.
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion> */}
                  <div className="card border-0">
                    <div
                      className="card-header border-none"
                      style={{
                        backgroundColor: "#194b88",
                      }}
                    >
                      <span className="fs-3">
                        <strong>The challenge</strong>
                      </span>
                    </div>
                    <div
                      className=" fs-5 card-body"
                      style={{
                        color: "black",
                        textShadow: "none",
                      }}
                    >
                      The new market cycle is changing the game for product
                      issuers. The end of the 12 years bull market will expose
                      the inadequacy of many investable products that prospered
                      in the good days. As investors realize that a passive
                      product can be a source of repetitive losses, they will
                      shift assets elsewhere. And the opportunity is filling the
                      inevitable future demand of intelligent products that can
                      deliver gains also in a low to negative returns cycle that
                      can last years.
                    </div>
                  </div>
                  <div className="card border-0 mt-3">
                    <div
                      className="card-header border-none"
                      style={{
                        backgroundColor: "#194b88",
                      }}
                    >
                      <span className="fs-3">
                        <strong>The solution</strong>
                      </span>
                    </div>
                    <div
                      className=" fs-5 card-body"
                      style={{
                        color: "black",
                        textShadow: "none",
                      }}
                    >
                      The way forward is advanced active management, profiting
                      from the recurrent performance dispersion inherent in any
                      investment universe across different market cycles.
                      Farsighted operators launching innovative products will
                      capture a significant share of the assets reallocation at
                      the expense of those that will persist with a conventional
                      offer range, detached from the new course. Trendrating
                      solution can support the launch of long and short products
                      combining fundamentals and trend capture models.
                    </div>
                  </div>
                </Col>
              )}
            </>
          </Row>
        </Container>
      </div>
      <div className="home-header-content--part2b">
        <Container className="pt-4 pb-5">
          <Row className="justify-content-center">
            <Col xs={12} sm={12} md={8} className="d-flex">
              <span className="fs-1 title text-center">
                Trendrating Rating Grades Helps Separate the Winners from the
                Losers
              </span>
            </Col>
            <Col xs={12} md={10} className="d-flex justify-content-center">
              <Col xs={12} sm={10}>
                <img
                  src="/images/chart-home.jpg"
                  className="img-fluid"
                  alt=""
                />
              </Col>
            </Col>
            <Col xs={12} className="d-flex justify-content-center">
              <span className="fs-5 disclaimer">
                (Example for illustration purpose only.)
              </span>
            </Col>
            <Col xs={12} className="mt-5 d-flex justify-content-center">
              <span className="fs-3 text-center">
                Our smart model portfolios use a systematic strategy combining
                fundamentals and our trend discovery model.
                <br />
                We look for a logical combination of solid foundation for
                supporting a trend and pragmatic confirmation that the right
                trend is in motion.
              </span>
            </Col>
            <Col>
              <table className="mb-5 mt-5 heroTable">
                <tr className=" heroTable__headers">
                  {/* <th>&nbsp;</th>
                      <th>US</th>
                      <th>Developed Europe</th>
                      <th>Asia Pacific</th> */}
                </tr>
                <tr>
                  <td>Good value</td>
                  <td>Lowest price/earnings and lowest price/sales </td>
                  <td>
                    + positive trend rating (<span className="ratingA">A</span>{" "}
                    and
                    <span className="ratingB"> B</span>)
                  </td>
                  <td>LONG POSITIONS</td>
                </tr>
                <tr>
                  <td>Poor value</td>
                  <td>Highest price/earnings and highest price/sales </td>
                  <td>
                    + negative trend rating (<span className="ratingC">C</span>{" "}
                    and
                    <span className="ratingD"> D</span>)
                  </td>
                  <td>SHORT POSITIONS </td>
                </tr>
                <tr>
                  <td>Strong growth</td>
                  <td>Highest growth of sales and earnings </td>
                  <td>
                    + positive trend rating (<span className="ratingA">A</span>{" "}
                    and
                    <span className="ratingB"> B</span>)
                  </td>
                  <td>LONG POSITIONS </td>
                </tr>
                <tr>
                  <td>Weak growth</td>
                  <td>Lowest growth of sales and earnings </td>
                  <td>
                    + negative trend rating (<span className="ratingC">C</span>{" "}
                    and
                    <span className="ratingD"> D</span>)
                  </td>
                  <td>SHORT POSITIONS </td>
                </tr>
              </table>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="home-header-content home-header-content--part2">
        <div className="home-header-content-mask"></div>
        <Container className="pb-2 pt-2">
          <Row className="align-items-md-stretch justify-content-center pb-2 pt-2">
            <Col md={10} className="home-header-content-cell text-center">
              <h5 className="text-light mt-5 mb-5 fs-1">
                Delivering superior returns on a consistent basis requires the
                adoption of advanced analytics and models that can capture the
                increasing dispersion of performance in any investment universe.
              </h5>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="home-header-content--part2a">
        <div className="home-header-content-mask"></div>

        <Container>
          <Row className="mt-5">
            <Col className="" lg={4}>
              <div className="card h-100 shadow">
                <div className="card-body">
                  <span className="topic card-title fs-3"> What </span>
                  <p className="fs-5">
                    we offer long and short strategies designed to:
                  </p>
                  <ul
                    className="unorderedList"
                    style={{
                      listStyle: "none",
                      padding: "0",
                    }}
                  >
                    <li className="fs-6">
                      <i
                        style={{
                          color: "green",
                        }}
                        className="bi bi-check "
                      ></i>
                      &nbsp;Profit from the performance dispersion across
                      stocks.
                    </li>
                    <li className="fs-6">
                      <i
                        style={{
                          color: "green",
                        }}
                        className="bi bi-check "
                      ></i>
                      &nbsp;Maximize investment returns.
                    </li>
                    <li className="fs-6">
                      <i
                        style={{
                          color: "green",
                        }}
                        className="bi bi-check "
                      ></i>
                      &nbsp;Reduce risks.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            {/* --- */}
            <Col className="" lg={4}>
              <div className="card h-100 shadow">
                <div className="card-body">
                  <span className="topic fs-3 card-title">Why</span>
                  <p className="fs-5">
                    Stock markets are now in a new, more challenging cycle.
                    Volatility, low returns are here to stay. Long and short
                    strategies are better tuned to handle the current market
                    course.
                    {/* <br />
                    The market cycles over the last 40 years: */}
                  </p>
                  {/* <ul
                    className="unorderedList"
                    style={{
                      listStyleImage: "url('/images/icons/clapperboard.png')",
                    }}
                  >
                    <li className="fs-6">
                      1982-1999 annualized return 15.34% (
                      <span className="positiveValue">High return</span>
                      ).
                    </li>
                    <li className="fs-6">
                      2000-2010 annualized return 0.7% (
                      <span className="negativeValue">Low return</span>
                      ).
                    </li>
                    <li className="fs-6">
                      2011-2021 annualized return 11% (
                      <span className="positiveValue">High return</span>
                      ).
                    </li>
                    <li className="fs-6">
                      2022 - until when ? (
                      <span className="negativeValue">Low return</span>
                      ).
                    </li>
                  </ul> */}
                </div>
              </div>
            </Col>
            {/* --- */}
            <Col className="" lg={4}>
              <div className="card  shadow">
                <div className="card-body">
                  <span className="topic card-title fs-3"> How</span>
                  <p className="fs-5">
                    Trendrating Smart Model Portfolios provides a list of well
                    tested investment strategies with different risk/reward
                    profiles that are easy to track, validate and execute.
                    <br />
                    Select your strategy and capture the benefits of our unique
                    solution, based on state of the art systematic models:
                  </p>
                  <ul
                    className="unorderedList"
                    style={{
                      listStyle: "none",
                      padding: "0",
                    }}
                  >
                    <li className="fs-6">
                      <i
                        style={{
                          color: "green",
                        }}
                        className="bi bi-check "
                      ></i>
                      &nbsp;Full transparency and testability.
                    </li>
                    <li className="fs-6">
                      <i
                        style={{
                          color: "green",
                        }}
                        className="bi bi-check "
                      ></i>
                      &nbsp;Superior performance.
                    </li>
                    <li className="fs-6">
                      <i
                        style={{
                          color: "green",
                        }}
                        className="bi bi-check "
                      ></i>
                      &nbsp;Full scalability.
                    </li>
                    <li className="fs-6">
                      <i
                        style={{
                          color: "green",
                        }}
                        className="bi bi-check "
                      ></i>
                      &nbsp;Save time.
                    </li>
                    <li className="fs-6">
                      <i
                        style={{
                          color: "green",
                        }}
                        className="bi bi-check "
                      ></i>
                      &nbsp;Costs reduction.
                    </li>
                    <li className="fs-6">
                      <i
                        style={{
                          color: "green",
                        }}
                        className="bi bi-check "
                      ></i>
                      &nbsp;Launch new products and generate additional
                      revenues.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="pb-5 pt-5">
          <Row>
            <Col>
              <span className="">
                <p className="text-center fs-4">
                  <strong style={{ color: "#194b88" }}>
                    Using a strategy that combines long and short positions can
                    offer a plan to achieve interesting returns while reducing
                    risks.
                  </strong>
                  <br />
                  <strong style={{ color: "#194b88" }}>
                    Sign up and access for free for 30 days.
                  </strong>
                </p>
              </span>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="home-header-content home-header-content--part3">
        <div className="home-header-content-mask"></div>
        <Container className="pb-2 pt-2">
          <Row className="align-items-md-stretch justify-content-center pb-2 pt-2">
            <Col
              md={10}
              className="home-header-content-cell text-center mb-5 mt-5"
            >
              <Link
                to={paths.product}
                className="home-button-action button-zoom btn btn-lg mt-3 me-1 btn-primary px-5 fs-3 btn-rounded"
              >
                Discover our Smart Model Portfolios
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
